exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-android-retail-app-download-js": () => import("./../../../src/pages/android-retail-app-download.js" /* webpackChunkName: "component---src-pages-android-retail-app-download-js" */),
  "component---src-pages-bill-payments-electricity-js": () => import("./../../../src/pages/bill-payments/electricity.js" /* webpackChunkName: "component---src-pages-bill-payments-electricity-js" */),
  "component---src-pages-blog-blog-url-js": () => import("./../../../src/pages/blog/[blogUrl].js" /* webpackChunkName: "component---src-pages-blog-blog-url-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-view-jobs-js": () => import("./../../../src/pages/careers/view-jobs.js" /* webpackChunkName: "component---src-pages-careers-view-jobs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-ca-index-js": () => import("./../../../src/pages/en-ca/index.js" /* webpackChunkName: "component---src-pages-en-ca-index-js" */),
  "component---src-pages-en-gb-about-js": () => import("./../../../src/pages/en-gb/about.js" /* webpackChunkName: "component---src-pages-en-gb-about-js" */),
  "component---src-pages-en-gb-accounts-js": () => import("./../../../src/pages/en-gb/accounts.js" /* webpackChunkName: "component---src-pages-en-gb-accounts-js" */),
  "component---src-pages-en-gb-contact-js": () => import("./../../../src/pages/en-gb/contact.js" /* webpackChunkName: "component---src-pages-en-gb-contact-js" */),
  "component---src-pages-en-gb-index-js": () => import("./../../../src/pages/en-gb/index.js" /* webpackChunkName: "component---src-pages-en-gb-index-js" */),
  "component---src-pages-en-gb-legal-complaints-policy-js": () => import("./../../../src/pages/en-gb/legal/complaints-policy.js" /* webpackChunkName: "component---src-pages-en-gb-legal-complaints-policy-js" */),
  "component---src-pages-en-gb-legal-cookie-policy-js": () => import("./../../../src/pages/en-gb/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-en-gb-legal-cookie-policy-js" */),
  "component---src-pages-en-gb-legal-faq-index-js": () => import("./../../../src/pages/en-gb/legal/faq/index.js" /* webpackChunkName: "component---src-pages-en-gb-legal-faq-index-js" */),
  "component---src-pages-en-gb-legal-index-js": () => import("./../../../src/pages/en-gb/legal/index.js" /* webpackChunkName: "component---src-pages-en-gb-legal-index-js" */),
  "component---src-pages-en-gb-legal-privacy-policy-js": () => import("./../../../src/pages/en-gb/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-gb-legal-privacy-policy-js" */),
  "component---src-pages-en-gb-legal-terms-js": () => import("./../../../src/pages/en-gb/legal/terms.js" /* webpackChunkName: "component---src-pages-en-gb-legal-terms-js" */),
  "component---src-pages-en-gb-payments-js": () => import("./../../../src/pages/en-gb/payments.js" /* webpackChunkName: "component---src-pages-en-gb-payments-js" */),
  "component---src-pages-en-ng-airtime-js": () => import("./../../../src/pages/en-ng/airtime.js" /* webpackChunkName: "component---src-pages-en-ng-airtime-js" */),
  "component---src-pages-en-ng-bill-payments-betting-js": () => import("./../../../src/pages/en-ng/bill-payments/betting.js" /* webpackChunkName: "component---src-pages-en-ng-bill-payments-betting-js" */),
  "component---src-pages-en-ng-bill-payments-cowry-js": () => import("./../../../src/pages/en-ng/bill-payments/cowry.js" /* webpackChunkName: "component---src-pages-en-ng-bill-payments-cowry-js" */),
  "component---src-pages-en-ng-bill-payments-internet-js": () => import("./../../../src/pages/en-ng/bill-payments/internet.js" /* webpackChunkName: "component---src-pages-en-ng-bill-payments-internet-js" */),
  "component---src-pages-en-ng-bill-payments-js": () => import("./../../../src/pages/en-ng/bill-payments.js" /* webpackChunkName: "component---src-pages-en-ng-bill-payments-js" */),
  "component---src-pages-en-ng-bill-payments-tv-js": () => import("./../../../src/pages/en-ng/bill-payments/tv.js" /* webpackChunkName: "component---src-pages-en-ng-bill-payments-tv-js" */),
  "component---src-pages-en-ng-borrow-js": () => import("./../../../src/pages/en-ng/borrow.js" /* webpackChunkName: "component---src-pages-en-ng-borrow-js" */),
  "component---src-pages-en-ng-budget-js": () => import("./../../../src/pages/en-ng/budget.js" /* webpackChunkName: "component---src-pages-en-ng-budget-js" */),
  "component---src-pages-en-ng-business-api-application-form-js": () => import("./../../../src/pages/en-ng/business/api-application-form.js" /* webpackChunkName: "component---src-pages-en-ng-business-api-application-form-js" */),
  "component---src-pages-en-ng-business-bill-payments-airtime-internet-data-js": () => import("./../../../src/pages/en-ng/business/bill-payments/airtime-internet-data.js" /* webpackChunkName: "component---src-pages-en-ng-business-bill-payments-airtime-internet-data-js" */),
  "component---src-pages-en-ng-business-bill-payments-cable-tv-js": () => import("./../../../src/pages/en-ng/business/bill-payments/cable-tv.js" /* webpackChunkName: "component---src-pages-en-ng-business-bill-payments-cable-tv-js" */),
  "component---src-pages-en-ng-business-bill-payments-electricity-meter-units-js": () => import("./../../../src/pages/en-ng/business/bill-payments/electricity-meter-units.js" /* webpackChunkName: "component---src-pages-en-ng-business-bill-payments-electricity-meter-units-js" */),
  "component---src-pages-en-ng-business-bill-payments-index-js": () => import("./../../../src/pages/en-ng/business/bill-payments/index.js" /* webpackChunkName: "component---src-pages-en-ng-business-bill-payments-index-js" */),
  "component---src-pages-en-ng-business-business-loan-application-form-js": () => import("./../../../src/pages/en-ng/business/business-loan-application-form.js" /* webpackChunkName: "component---src-pages-en-ng-business-business-loan-application-form-js" */),
  "component---src-pages-en-ng-business-cashback-airtime-bills-js": () => import("./../../../src/pages/en-ng/business/cashback-airtime-bills.js" /* webpackChunkName: "component---src-pages-en-ng-business-cashback-airtime-bills-js" */),
  "component---src-pages-en-ng-business-expense-accounts-js": () => import("./../../../src/pages/en-ng/business/expense-accounts.js" /* webpackChunkName: "component---src-pages-en-ng-business-expense-accounts-js" */),
  "component---src-pages-en-ng-business-expense-card-js": () => import("./../../../src/pages/en-ng/business/expense-card.js" /* webpackChunkName: "component---src-pages-en-ng-business-expense-card-js" */),
  "component---src-pages-en-ng-business-index-js": () => import("./../../../src/pages/en-ng/business/index.js" /* webpackChunkName: "component---src-pages-en-ng-business-index-js" */),
  "component---src-pages-en-ng-business-invoicing-js": () => import("./../../../src/pages/en-ng/business/invoicing.js" /* webpackChunkName: "component---src-pages-en-ng-business-invoicing-js" */),
  "component---src-pages-en-ng-business-loans-js": () => import("./../../../src/pages/en-ng/business/loans.js" /* webpackChunkName: "component---src-pages-en-ng-business-loans-js" */),
  "component---src-pages-en-ng-business-open-api-js": () => import("./../../../src/pages/en-ng/business/open-api.js" /* webpackChunkName: "component---src-pages-en-ng-business-open-api-js" */),
  "component---src-pages-en-ng-business-payroll-js": () => import("./../../../src/pages/en-ng/business/payroll.js" /* webpackChunkName: "component---src-pages-en-ng-business-payroll-js" */),
  "component---src-pages-en-ng-business-payroll-software-js": () => import("./../../../src/pages/en-ng/business/payroll-software.js" /* webpackChunkName: "component---src-pages-en-ng-business-payroll-software-js" */),
  "component---src-pages-en-ng-business-pos-form-js": () => import("./../../../src/pages/en-ng/business/pos-form.js" /* webpackChunkName: "component---src-pages-en-ng-business-pos-form-js" */),
  "component---src-pages-en-ng-business-pos-js": () => import("./../../../src/pages/en-ng/business/pos.js" /* webpackChunkName: "component---src-pages-en-ng-business-pos-js" */),
  "component---src-pages-en-ng-business-registration-cac-tin-js": () => import("./../../../src/pages/en-ng/business/registration-cac-tin.js" /* webpackChunkName: "component---src-pages-en-ng-business-registration-cac-tin-js" */),
  "component---src-pages-en-ng-business-send-money-js": () => import("./../../../src/pages/en-ng/business/send-money.js" /* webpackChunkName: "component---src-pages-en-ng-business-send-money-js" */),
  "component---src-pages-en-ng-business-softpos-payments-js": () => import("./../../../src/pages/en-ng/business/softpos-payments.js" /* webpackChunkName: "component---src-pages-en-ng-business-softpos-payments-js" */),
  "component---src-pages-en-ng-business-virtual-pos-js": () => import("./../../../src/pages/en-ng/business/virtual-pos.js" /* webpackChunkName: "component---src-pages-en-ng-business-virtual-pos-js" */),
  "component---src-pages-en-ng-cardless-payments-js": () => import("./../../../src/pages/en-ng/cardless-payments.js" /* webpackChunkName: "component---src-pages-en-ng-cardless-payments-js" */),
  "component---src-pages-en-ng-cards-js": () => import("./../../../src/pages/en-ng/cards.js" /* webpackChunkName: "component---src-pages-en-ng-cards-js" */),
  "component---src-pages-en-ng-gift-cards-js": () => import("./../../../src/pages/en-ng/gift-cards.js" /* webpackChunkName: "component---src-pages-en-ng-gift-cards-js" */),
  "component---src-pages-en-ng-index-js": () => import("./../../../src/pages/en-ng/index.js" /* webpackChunkName: "component---src-pages-en-ng-index-js" */),
  "component---src-pages-en-ng-investments-js": () => import("./../../../src/pages/en-ng/investments.js" /* webpackChunkName: "component---src-pages-en-ng-investments-js" */),
  "component---src-pages-en-ng-kudamfb-js": () => import("./../../../src/pages/en-ng/kudamfb.js" /* webpackChunkName: "component---src-pages-en-ng-kudamfb-js" */),
  "component---src-pages-en-ng-legal-cards-js": () => import("./../../../src/pages/en-ng/legal/cards.js" /* webpackChunkName: "component---src-pages-en-ng-legal-cards-js" */),
  "component---src-pages-en-ng-legal-cookie-policy-js": () => import("./../../../src/pages/en-ng/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-en-ng-legal-cookie-policy-js" */),
  "component---src-pages-en-ng-legal-disclaimer-gift-cards-js": () => import("./../../../src/pages/en-ng/legal/disclaimer/giftCards.js" /* webpackChunkName: "component---src-pages-en-ng-legal-disclaimer-gift-cards-js" */),
  "component---src-pages-en-ng-legal-disclaimer-investments-js": () => import("./../../../src/pages/en-ng/legal/disclaimer/investments.js" /* webpackChunkName: "component---src-pages-en-ng-legal-disclaimer-investments-js" */),
  "component---src-pages-en-ng-legal-faq-business-js": () => import("./../../../src/pages/en-ng/legal/faq/business.js" /* webpackChunkName: "component---src-pages-en-ng-legal-faq-business-js" */),
  "component---src-pages-en-ng-legal-faq-index-js": () => import("./../../../src/pages/en-ng/legal/faq/index.js" /* webpackChunkName: "component---src-pages-en-ng-legal-faq-index-js" */),
  "component---src-pages-en-ng-legal-faq-open-api-js": () => import("./../../../src/pages/en-ng/legal/faq/openApi.js" /* webpackChunkName: "component---src-pages-en-ng-legal-faq-open-api-js" */),
  "component---src-pages-en-ng-legal-index-js": () => import("./../../../src/pages/en-ng/legal/index.js" /* webpackChunkName: "component---src-pages-en-ng-legal-index-js" */),
  "component---src-pages-en-ng-legal-information-security-policy-js": () => import("./../../../src/pages/en-ng/legal/information-security-policy.js" /* webpackChunkName: "component---src-pages-en-ng-legal-information-security-policy-js" */),
  "component---src-pages-en-ng-legal-privacy-policy-js": () => import("./../../../src/pages/en-ng/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-ng-legal-privacy-policy-js" */),
  "component---src-pages-en-ng-legal-savings-js": () => import("./../../../src/pages/en-ng/legal/savings.js" /* webpackChunkName: "component---src-pages-en-ng-legal-savings-js" */),
  "component---src-pages-en-ng-legal-terms-business-js": () => import("./../../../src/pages/en-ng/legal/terms/business.js" /* webpackChunkName: "component---src-pages-en-ng-legal-terms-business-js" */),
  "component---src-pages-en-ng-legal-terms-index-js": () => import("./../../../src/pages/en-ng/legal/terms/index.js" /* webpackChunkName: "component---src-pages-en-ng-legal-terms-index-js" */),
  "component---src-pages-en-ng-overdraft-eligibility-form-js": () => import("./../../../src/pages/en-ng/overdraft-eligibility-form.js" /* webpackChunkName: "component---src-pages-en-ng-overdraft-eligibility-form-js" */),
  "component---src-pages-en-ng-overdraft-eligibility-js": () => import("./../../../src/pages/en-ng/overdraft-eligibility.js" /* webpackChunkName: "component---src-pages-en-ng-overdraft-eligibility-js" */),
  "component---src-pages-en-ng-overdrafts-js": () => import("./../../../src/pages/en-ng/overdrafts.js" /* webpackChunkName: "component---src-pages-en-ng-overdrafts-js" */),
  "component---src-pages-en-ng-personal-loan-js": () => import("./../../../src/pages/en-ng/personal-loan.js" /* webpackChunkName: "component---src-pages-en-ng-personal-loan-js" */),
  "component---src-pages-en-ng-personal-loan-loan-calculator-js": () => import("./../../../src/pages/en-ng/personal-loan/loan-calculator.js" /* webpackChunkName: "component---src-pages-en-ng-personal-loan-loan-calculator-js" */),
  "component---src-pages-en-ng-save-js": () => import("./../../../src/pages/en-ng/save.js" /* webpackChunkName: "component---src-pages-en-ng-save-js" */),
  "component---src-pages-en-ng-spend-js": () => import("./../../../src/pages/en-ng/spend.js" /* webpackChunkName: "component---src-pages-en-ng-spend-js" */),
  "component---src-pages-en-ng-virtual-cards-js": () => import("./../../../src/pages/en-ng/virtual-cards.js" /* webpackChunkName: "component---src-pages-en-ng-virtual-cards-js" */),
  "component---src-pages-erica-js": () => import("./../../../src/pages/erica.js" /* webpackChunkName: "component---src-pages-erica-js" */),
  "component---src-pages-global-js": () => import("./../../../src/pages/global.js" /* webpackChunkName: "component---src-pages-global-js" */),
  "component---src-pages-incentive-program-account-credit-js": () => import("./../../../src/pages/incentive-program/account-credit.js" /* webpackChunkName: "component---src-pages-incentive-program-account-credit-js" */),
  "component---src-pages-incentive-program-birthday-credit-js": () => import("./../../../src/pages/incentive-program/birthday-credit.js" /* webpackChunkName: "component---src-pages-incentive-program-birthday-credit-js" */),
  "component---src-pages-incentive-program-birthday-js": () => import("./../../../src/pages/incentive-program/birthday.js" /* webpackChunkName: "component---src-pages-incentive-program-birthday-js" */),
  "component---src-pages-incentive-program-business-cashback-js": () => import("./../../../src/pages/incentive-program/business-cashback.js" /* webpackChunkName: "component---src-pages-incentive-program-business-cashback-js" */),
  "component---src-pages-incentive-program-cashback-refund-js": () => import("./../../../src/pages/incentive-program/cashback-refund.js" /* webpackChunkName: "component---src-pages-incentive-program-cashback-refund-js" */),
  "component---src-pages-incentive-program-prize-draw-js": () => import("./../../../src/pages/incentive-program/prize-draw.js" /* webpackChunkName: "component---src-pages-incentive-program-prize-draw-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-kuda-js": () => import("./../../../src/pages/joinKuda.js" /* webpackChunkName: "component---src-pages-join-kuda-js" */),
  "component---src-pages-joinkudabusiness-js": () => import("./../../../src/pages/joinkudabusiness.js" /* webpackChunkName: "component---src-pages-joinkudabusiness-js" */),
  "component---src-pages-kuda-premium-invitation-accepted-js": () => import("./../../../src/pages/kuda-premium/invitation-accepted.js" /* webpackChunkName: "component---src-pages-kuda-premium-invitation-accepted-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-ng-index-js": () => import("./../../../src/pages/legal/ng/index.js" /* webpackChunkName: "component---src-pages-legal-ng-index-js" */),
  "component---src-pages-legal-ng-privacy-js": () => import("./../../../src/pages/legal/ng-privacy.js" /* webpackChunkName: "component---src-pages-legal-ng-privacy-js" */),
  "component---src-pages-legal-ug-index-js": () => import("./../../../src/pages/legal/ug/index.js" /* webpackChunkName: "component---src-pages-legal-ug-index-js" */),
  "component---src-pages-legal-ug-privacy-js": () => import("./../../../src/pages/legal/ug-privacy.js" /* webpackChunkName: "component---src-pages-legal-ug-privacy-js" */),
  "component---src-pages-legal-uk-index-js": () => import("./../../../src/pages/legal/uk/index.js" /* webpackChunkName: "component---src-pages-legal-uk-index-js" */),
  "component---src-pages-legal-uk-privacy-js": () => import("./../../../src/pages/legal/uk-privacy.js" /* webpackChunkName: "component---src-pages-legal-uk-privacy-js" */),
  "component---src-pages-mobile-ng-legal-cards-js": () => import("./../../../src/pages/mobile/ng/legal/cards.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-cards-js" */),
  "component---src-pages-mobile-ng-legal-cookie-policy-js": () => import("./../../../src/pages/mobile/ng/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-cookie-policy-js" */),
  "component---src-pages-mobile-ng-legal-disclaimer-gift-cards-js": () => import("./../../../src/pages/mobile/ng/legal/disclaimer/giftCards.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-disclaimer-gift-cards-js" */),
  "component---src-pages-mobile-ng-legal-disclaimer-investments-js": () => import("./../../../src/pages/mobile/ng/legal/disclaimer/investments.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-disclaimer-investments-js" */),
  "component---src-pages-mobile-ng-legal-faq-business-js": () => import("./../../../src/pages/mobile/ng/legal/faq/business.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-faq-business-js" */),
  "component---src-pages-mobile-ng-legal-faq-index-js": () => import("./../../../src/pages/mobile/ng/legal/faq/index.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-faq-index-js" */),
  "component---src-pages-mobile-ng-legal-faq-open-api-js": () => import("./../../../src/pages/mobile/ng/legal/faq/openApi.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-faq-open-api-js" */),
  "component---src-pages-mobile-ng-legal-index-js": () => import("./../../../src/pages/mobile/ng/legal/index.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-index-js" */),
  "component---src-pages-mobile-ng-legal-information-security-policy-js": () => import("./../../../src/pages/mobile/ng/legal/information-security-policy.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-information-security-policy-js" */),
  "component---src-pages-mobile-ng-legal-privacy-policy-js": () => import("./../../../src/pages/mobile/ng/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-privacy-policy-js" */),
  "component---src-pages-mobile-ng-legal-savings-js": () => import("./../../../src/pages/mobile/ng/legal/savings.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-savings-js" */),
  "component---src-pages-mobile-ng-legal-terms-business-js": () => import("./../../../src/pages/mobile/ng/legal/terms/business.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-terms-business-js" */),
  "component---src-pages-mobile-ng-legal-terms-index-js": () => import("./../../../src/pages/mobile/ng/legal/terms/index.js" /* webpackChunkName: "component---src-pages-mobile-ng-legal-terms-index-js" */),
  "component---src-pages-mobile-uk-legal-complaints-policy-js": () => import("./../../../src/pages/mobile/uk/legal/complaints-policy.js" /* webpackChunkName: "component---src-pages-mobile-uk-legal-complaints-policy-js" */),
  "component---src-pages-mobile-uk-legal-cookie-policy-js": () => import("./../../../src/pages/mobile/uk/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-mobile-uk-legal-cookie-policy-js" */),
  "component---src-pages-mobile-uk-legal-faq-index-js": () => import("./../../../src/pages/mobile/uk/legal/faq/index.js" /* webpackChunkName: "component---src-pages-mobile-uk-legal-faq-index-js" */),
  "component---src-pages-mobile-uk-legal-index-js": () => import("./../../../src/pages/mobile/uk/legal/index.js" /* webpackChunkName: "component---src-pages-mobile-uk-legal-index-js" */),
  "component---src-pages-mobile-uk-legal-privacy-policy-js": () => import("./../../../src/pages/mobile/uk/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-mobile-uk-legal-privacy-policy-js" */),
  "component---src-pages-mobile-uk-legal-terms-js": () => import("./../../../src/pages/mobile/uk/legal/terms.js" /* webpackChunkName: "component---src-pages-mobile-uk-legal-terms-js" */),
  "component---src-pages-newsletter-email-response-a-js": () => import("./../../../src/pages/newsletter-email/responseA.js" /* webpackChunkName: "component---src-pages-newsletter-email-response-a-js" */),
  "component---src-pages-newsletter-email-response-b-js": () => import("./../../../src/pages/newsletter-email/responseB.js" /* webpackChunkName: "component---src-pages-newsletter-email-response-b-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-scam-awareness-js": () => import("./../../../src/pages/scam-awareness.js" /* webpackChunkName: "component---src-pages-scam-awareness-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

